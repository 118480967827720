<template>
  <div id="map">
    <!-- loader -->
    <div v-if="photos.length === 0" class="global-loading">
      <div v-html="translate.global.loading_exhibition_data[language] + ' &hellip;'"></div>
    </div>
    <!-- header -->
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]" />
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <!-- map -->
        <div class="col">
          <h1 v-html="translate.global.map[language]"></h1>
          <osmap v-if="map"
            v-bind:map="map"></osmap>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import osmap from '@/components/partials/Maplarge.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Map',
  components: {
    'site-header': header,
    osmap
  },
  methods: {
    ...mapActions([
      'items/fetchMap'
    ])
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      photos: 'items/photos',
      map: 'items/map'
    })
  },
  created () {
    this['items/fetchMap']()
  }
}
</script>