<template>
  <l-map
    style="height:800px;"
    class="largemap"
    :zoom="zoom"
    :center="latitudeLongitude"
    @update:center="centerUpdate"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <marker-popup
      v-for="(item, index) in map"
      :key="'marker_popup_' + index"
      :position="getPos(item)" :item="item" :title="title(item)"></marker-popup>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import L from 'leaflet'
import MarkerPopup from './MarkerPopupLarge';
import { mapGetters } from 'vuex'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../assets/img/marker-icon-2x.png'),
  iconUrl: require('../../assets/img/marker-icon.png'),
  shadowUrl: require('../../assets/img/marker-shadow.png')
});

export default {
  name: 'OSMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    MarkerPopup
  },
  data () {
    return {
      zoom: 7,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      currentCenter: null
    }
  },
  props: {
    map: Array
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate'
    }),
    latitudeLongitude: function () {
      return L.latLng(51.397722, 16.2095788)
    },
    marker: function () {
      return L.latLng(this.coordinates.latitude, this.coordinates.longitude)
    }
  },
  methods: {
    centerUpdate () {
      return L.latLng(this.map[0].latitude, this.map[0].longitude)
    },
    getPos(item) {
      return L.latLng(item.latitude, item.longitude)
    },
    title(item) {
      let title = item.locationdisplay.find(loc =>
        loc['@language'] === this.language)
      return title['@value']
    }
  }
}
</script>
<style lang="scss">
.leaflet-fake-icon-image-2x {
  background-image: url(../../../node_modules/leaflet/dist/images/marker-icon-2x.png);
}
.leaflet-fake-icon-shadow {
  background-image: url(../../../node_modules/leaflet/dist/images/marker-shadow.png);
}
@import "../../../node_modules/leaflet/dist/leaflet.css";

.leaflet-popup-content p {
  margin: 8px 0;
  font-size: 1rem;
}
</style>
