<template>
  <l-marker :lat-lng="position" :title="title" :draggable="false">
    <l-popup>
      <strong v-html="title"></strong>
      <div
        v-if="item.photos.length > 0"
        v-for="(photo, index) in item.photos"
        :key="'popup_link_' + index"
        class="text-center mb-2"
      >
        <router-link :to="{ name: 'item', params: { id: photo.id }}">
          <img class="map_popup_image" v-if="photo.media" :src="photo.media" :alt="'photo no. ' + photo.lfdnr">
          <div class="mt-1">{{ translate.global.foto[language] }} {{ translate.global.nr[language] }} {{ photo.lfdnr }}</div>
        </router-link>
      </div>
    </l-popup>
  </l-marker>
</template>
<script>
import { LMarker, LPopup } from 'vue2-leaflet';
import { mapGetters } from 'vuex'
export default {
  name: 'MarkerPopupLarge',
  components: {
    LMarker,
    LPopup
  },
  props: ['item', 'position', 'title'],
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate'
    })
  }
}
</script>